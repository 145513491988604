// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DIALOG_BUTTON_TYPE,
  DialogButton,
  DialogMessageContents,
  ModalDialog,
  SpinnerOverlayView,
} from '@/components/Elements';
import { API_STATUS } from '@/constants';

import { useShareEndConfirmDialog } from '../hooks/useShareEndConfirmDialog';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
  // 共有終了成功/失敗時に実行したい処理
  onShareEndCompletedFun?: () => void;
};

/**
 * 共有終了確認ダイアログ コンポーネント
 */
export const ShareEndConfirmDialog = React.memo(
  ({ isOpen, onClose, onShareEndCompletedFun }: Props) => {
    const { clickEndButton, deleteViewState } = useShareEndConfirmDialog({
      onShareEndCompletedFun,
      onCloseShareEndConfirmDialog: onClose,
    });
    const { t } = useTranslation();

    const shareEndConfirmDialogButtons: DialogButton[] = [
      {
        label: t('shareEndConfirmDialog.キャンセル'),
        onClick: onClose,
        type: DIALOG_BUTTON_TYPE.CHANCEL,
        gtmClassTag: 'dialog-05',
      },

      {
        label: t('shareEndConfirmDialog.終了'),
        onClick: () => clickEndButton(),
        type: DIALOG_BUTTON_TYPE.WARNING,
        gtmClassTag: 'dialog-06',
      },
    ];

    return (
      <>
        <SpinnerOverlayView
          isOpen={deleteViewState.status === API_STATUS.LOADING}
        />
        <ModalDialog
          isOpen={isOpen && deleteViewState.status === API_STATUS.IDLE}
          isHiddenCloseButton
          onClickClose={onClose}
          title={t('shareEndConfirmDialog.共有を終了しますか？').toString()}
          buttons={shareEndConfirmDialogButtons}
        >
          <DialogMessageContents>
            {t('shareEndConfirmDialog.終了した場合、共有URLが無効になります。')}
          </DialogMessageContents>
        </ModalDialog>
      </>
    );
  },
);

import React from 'react';

import useOpen from '@/hooks/useOpen';

import { CampaignDialog } from './banner/CampaignDialog';

/**
 * 共有終了ビュー
 */
export const ShareEndView = React.memo(() => {
  const { isOpen, onClose } = useOpen(true);

  return (
    <div>
      {/* キャンペーンダイアログを表示 */}
      <CampaignDialog isOpen={isOpen} onClose={onClose} />
    </div>
  );
});

/* eslint-disable react/require-default-props */
import React from 'react';

import { DocErrorFunction } from '@/constants/firestore';
import {
  RecognizedTextsAddFunction,
  StreamingAddFunction,
  useRecognizedTextsCollection,
  useStreamingCollection,
  useViewConfigCollection,
  ViewConfigChangesFunction,
} from '@/features/firestore';

/**
 * プロパティ
 */
type Props = {
  // 翻訳先言語
  destlang: string;
  // 「共有画面設定(view>config)」が更新された時の処理
  onChangeViewConfig: ViewConfigChangesFunction;
  // 「ストリーミング翻訳(interim_texts/languages)」が追加された際の処理
  onResultAddStreaming: StreamingAddFunction;
  // 「確定テキスト(recognized_texts)」が追加された際の処理
  onResultAddRecognizedTexts: RecognizedTextsAddFunction;
  // Firestoreへの接続に失敗した際の処理
  onErrorFireStore: DocErrorFunction;
  // Firestoreの監視に使うuid(ゲスト画面表示中は使いまわす)
  firestoreDocumentId: string;
};

/**
 * 共有機能用 ゲスト画面ビュー
 */
export const ShareGuestView = React.memo(
  ({
    destlang,
    onChangeViewConfig,
    onResultAddStreaming,
    onResultAddRecognizedTexts,
    onErrorFireStore,
    firestoreDocumentId,
  }: Props) => {
    // ストリーミング翻訳の監視開始
    useStreamingCollection({
      documentId: firestoreDocumentId,
      destlang,
      onResultAddData: onResultAddStreaming,
      onDocError: onErrorFireStore,
    });
    // 確定テキストの監視開始
    useRecognizedTextsCollection({
      documentId: firestoreDocumentId,
      destlang,
      onResultAddData: onResultAddRecognizedTexts,
      onDocError: onErrorFireStore,
    });
    // 共有状況の監視
    useViewConfigCollection({
      documentId: firestoreDocumentId,
      onDocChanges: onChangeViewConfig,
      onDocError: onErrorFireStore,
    });

    return null;
  },
);

import { useCallback } from 'react';

import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';

import { useBrowserHistory } from './useBrowserHistory';
import { useBrowserTranslationDisplay } from './useBrowserTranslationDisplay';
import { useBrowserUserInfo } from './useBrowserUserInfo';

/**
 * ローカルストレージ削除用 hooks
 */
export const useClearLocalStorage = () => {
  const { resetState: resetBrowserUserSetting } = useBrowserUserSetting();
  const { resetState: resetLanguage } = useBrowserLanguage();
  const { resetHistoryListState } = useBrowserHistory();
  const { resetState: resetTranslationDisplay } =
    useBrowserTranslationDisplay();
  const { resetState: resetBrowserUserInfo } = useBrowserUserInfo();

  /**
   * ローカルストレージ削除を実行する
   */
  const doCleanLocalStorage = useCallback(() => {
    resetBrowserUserSetting();
    resetLanguage();
    resetHistoryListState();
    resetTranslationDisplay();
    resetBrowserUserInfo();
  }, [
    resetBrowserUserInfo,
    resetBrowserUserSetting,
    resetHistoryListState,
    resetLanguage,
    resetTranslationDisplay,
  ]);

  return {
    doCleanLocalStorage,
  };
};

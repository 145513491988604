import React from 'react';
import { useTranslation } from 'react-i18next';

import campaignImage from '@/assets/share-guest-campaign_image.svg';
import {
  Button,
  DialogMessageContents,
  ModalDialog,
} from '@/components/Elements';

import { useCampaignDialog } from '../../hooks/useCampaignDialog';

import styles from './CampaignDialog.module.scss';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * キャンペーンダイアログ コンポーネント
 */
export const CampaignDialog = React.memo(({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { openPurchaseLink } = useCampaignDialog();

  return (
    <ModalDialog
      isOpen={isOpen}
      isHiddenCloseButton={false}
      onClickClose={onClose}
      title=" "
    >
      <DialogMessageContents>
        <div className={styles['campaign-banner-container']}>
          <div className={styles['campaign-banner-ribbon']}>
            {t('shareGuestCampaign.この画面を見た方、限定価格')}
          </div>
          <div className={styles['campaign-banner-title']}>
            {t(
              'shareGuestCampaign.「ポケトーク・ライブ通訳」を会社や自宅で使えます',
            )}
          </div>
          <div className={styles['select-mic-button-image']}>
            <img src={campaignImage} alt="campaignImage" />
          </div>

          {t('shareGuestCampaign.リモートでも、対面でも')}
          <div className={styles['campaign-banner-button']}>
            <Button
              type="button"
              title={t('shareGuestCampaign.詳細')}
              onClick={() => {
                openPurchaseLink();
              }}
              gtmClassTag="dialog-12"
            />
          </div>
        </div>
      </DialogMessageContents>
    </ModalDialog>
  );
});
